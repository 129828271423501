<template>
    <div class="d-flex justify-content-center align-items-center" style="min-height:100svh">
        <b-card
                style="max-width: 40rem;"
                class="w-100 p-5 shadow-sm"
        >
        <img alt="logo" src="../../assets/launchyou.jpg" class="mb-5 img-fluid">
            
            
            <h6>New Password</h6>
            
            <div>
                <b-alert show variant="warning" v-if="showStatus">Password is changed, you can now <a href="/">Login</a></b-alert>

                <b-alert show variant="danger" v-if="showError">{{ errorMessage }}</b-alert>
                <b-form @submit="onSubmit" v-else>
                    <b-form-group
                            label="Password:"
                            label-for="password"
                    >
                        <b-form-input
                                v-model="form.password"
                                type="password"
                                placeholder="Enter Password"
                        ></b-form-input>
                        <b-alert show variant="warning" v-if="errors.password">{{ errors.password }}</b-alert>
                    </b-form-group>

                    <b-form-group
                            label="Confirm Password:"
                            label-for="confirm_password"
                    >
                        <b-form-input
                                v-model="form.confirm_password"
                                type="password"
                                placeholder="Enter Confirm Password"
                        ></b-form-input>
                        <b-alert show variant="warning" v-if="errors.confirm_password">{{ errors.confirm_password }}
                        </b-alert>
                    </b-form-group>

                    <b-button type="submit" variant="primary">Submit</b-button>
                </b-form>
            </div>

        </b-card>
    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        data() {
            return {
                form: {
                    password: '',
                    confirm_password: '',
                },
                showError: false,
                errorMessage: '',
                showStatus: false,
                status: false,
                errors: {password: null, confirm_password: null},
            }
        },
        created() {
            this.verifyCode({code: this.$route.params.code})
                .then(response => {
                    if (response.data.status == false) {
                        this.showError = true
                        this.errorMessage = 'The code is invalid.'
                    }
                })
                .catch(error => {
                    this.showError = true
                    this.errorMessage = error.response.data
                });
        },
        methods: {
            ...mapActions(['newPassword', 'verifyCode']),

            onSubmit(event) {
                event.preventDefault()
                this.showError = false;
                let password = this.form.password
                let confirm_password = this.form.confirm_password

                this.errors = {};

                if (password === '') {
                    this.errors.password = 'Password is required.';
                }


                if (confirm_password === '') {
                    this.errors.confirm_password = 'Confirm Password is required.';
                }

                if (password !== confirm_password) {
                    this.errors.confirm_password = 'Confirm Password is different than password.';
                }

                if (Object.keys(this.errors).length > 0) {
                    return false;
                }

                this.newPassword({
                    code: this.$route.params.code,
                    password: password,
                    confirm_password: confirm_password
                })
                    .then(response => {
                        this.showStatus = response.status
                    })
                    .catch(error => {
                        this.showError = true
                        const response = error.response.data.error
                        if (response.password) {
                            this.errors.password = response.password
                        }
                        else {
                            this.errorMessage = response
                        }
                    });
            }
        }
    }
</script>

